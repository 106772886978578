import { all, fork } from "redux-saga/effects";
import usersSaga from "./users/usersSaga";
import accountSaga from "./auth/accountSaga";
import roleScopesSaga from "./roles/roleScopesSaga";
import rolesSaga from "./roles/rolesSaga";
import articleCategorySaga from "./articles-category/articleCategorySaga";
import articleSaga from "./articles/articleSaga";
import productSaga from "./products/producrSaga";
import productCategorySaga from "./products-categroy/productCategorySaga";
import menusSaga from "./menus/menusSaga";
import usersGroupSaga from "./users-group/userGroupSaga";
import collectionSaga from "./collections/collectionSaga";
import productImageSaga from "./products-image/productImageSaga";
import BannerPositionSaga from "./banner-position/bannerPositionSaga";
import BannerConfigSaga from "./banner-config/bannerConfigSaga";
import bannerSaga from "./banner/bannerSaga";
import collectionTypeSaga from "./collections-type/collectionTypeSaga";
import CollectionGroupSaga from "./collections-group/collectionGroupSaga";
import CollectionItemSaga from "./collections-item/collectionItemSaga";
import keywordSaga from "./keyword/keywordSaga";

export default function* rootSagas() {
  yield all([fork(usersSaga)]);
  yield all([fork(usersGroupSaga)]);
  yield all([fork(menusSaga)]);
  yield all([fork(accountSaga)]);
  yield all([fork(rolesSaga)]);
  yield all([fork(roleScopesSaga)]);
  yield all([fork(articleCategorySaga)]);
  yield all([fork(articleSaga)]);
  yield all([fork(productSaga)]);
  yield all([fork(keywordSaga)]);
  yield all([fork(productCategorySaga)]);
  yield all([fork(BannerPositionSaga)]);
  yield all([fork(bannerSaga)])
  yield all([fork(BannerConfigSaga)])
  yield all([fork(productImageSaga)]);
  yield all([fork(collectionSaga)])
  yield all([fork(collectionTypeSaga)])
  yield all([fork(CollectionGroupSaga)])
  yield all([fork(CollectionItemSaga)])
}