import * as Yup from 'yup';
export type KeywordModel = {
    id: number;
    catId: number;
    name: string;
    slug: string;
    stt: number;
}

export const dfKeywordModel: KeywordModel = {
    id: 0,
    catId: 0,
    name: '',
    slug: '',
    stt: 0,
}

export const validKeywordSchema = () => Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập từ khóa sản phẩm.'),
    slug: Yup.string().required('Vui lòng nhập slug từ khóa.'),
});