import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { CollectionItemAction_DELETE_Request, CollectionItemAction_GETALL_Request, CollectionItemAction_GETPAGINATION_Request, CollectionItemAction_SAVE_Request, eCollectionItemActionTypeIds } from "./ICollectionItemActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { CollectionItemModel } from "../../models/collections/CollectionItemModel";
import { CollectionItemResponse } from "../../models/collections/CollectionItemResponse";

const _sagaApi = rootApi.collectionItem;

function* onLoadAllCollectionItem(action: CollectionItemAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetColectionItemByCollectionAsync],action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionItemResponse[];
            yield put(actions.collectionItem.getAllSuccess(listCollection));
            console.log(listCollection)
        }
        else {
            yield put(actions.collectionItem.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionItem.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationCollectionItem(action: CollectionItemAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listCollection = res.result as CollectionItemModel[];
            yield put(actions.collectionItem.getPaginationSuccess({ listDatas: listCollection, pagination: res.pagination }));
        }
        else {
            yield put(actions.collectionItem.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionItem.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveCollectionItem(action: CollectionItemAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collectionItem.saveSuccess(res.message));
                yield put(actions.collectionItem.needReload());
            }
            else {
                yield put(actions.collectionItem.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.collectionItem.saveSuccess(res.message));
                yield put(actions.collectionItem.needReload());
            }
            else {
                yield put(actions.collectionItem.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.collectionItem.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteCollectionItem(action: CollectionItemAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.collectionItem.deleteSuccess(res.message));
            yield put(actions.collectionItem.needReload());
        }
        else {
            yield put(actions.collectionItem.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.collectionItem.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllCollectionItem() {
    yield takeEvery(eCollectionItemActionTypeIds.GET_ALL_REQUEST, onLoadAllCollectionItem);
}

function* watchOnLoadPaginationCollectionItem() {
    yield takeEvery(eCollectionItemActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationCollectionItem);
}


function* watchOnSaveCollectionItem() {
    yield takeEvery(eCollectionItemActionTypeIds.SAVE_REQUEST, onSaveCollectionItem);
}

function* watchOnDeleteCollectionItem() {
    yield takeEvery(eCollectionItemActionTypeIds.DELETE_REQUEST, onDeleteCollectionItem);
}

function* CollectionItemSaga() {
    yield all([fork(watchOnLoadAllCollectionItem)]);
    yield all([fork(watchOnLoadPaginationCollectionItem)]);
    yield all([fork(watchOnSaveCollectionItem)]);
    yield all([fork(watchOnDeleteCollectionItem)]);
}

export default CollectionItemSaga;