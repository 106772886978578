import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';

import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import KeywordDetail from './KeywordDetail';
import { KeywordModel } from '../../context/models/keyword/KeywordModel';
import { KeywordActionTypes } from '../../context/stores/keyword/IKeywordActionsTypes';
import { useLookupProductCategory } from '../../components/shared/hoc-product-category/useLookupProductCategory';
import { KeywordResponse } from '../../context/models/keyword/KeywordResponse';
import ComboBoxProductCategory from '../../components/shared/hoc-product-category/ComboBoxProductCategory';

type IKeywordPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allKeywords: KeywordResponse[];
	selectedIds: number[];
	showDetail: BaseShowDetail<KeywordModel>;
	showConfirm: boolean;
	handleReloadAllData: (cat_id: number) => void;
	handleSaveData: (data: KeywordModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<KeywordModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Danh sách từ khóa nổi bật`,
		status: state.keyword.status,
		allKeywords: state.keyword.allKeywords,
		selectedIds: state.keyword.selectedIds,
		showDetail: state.keyword.showDetail,
		showConfirm: state.keyword.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<KeywordActionTypes>) => ({
	handleReloadAllData: (catID: number) => {
		dispatch(actions.keyword.getAllRequest({ catID }));
	},
	handleSaveData: (data: KeywordModel) => {
		dispatch(actions.keyword.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.keyword.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.keyword.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<KeywordModel>) => {
		dispatch(actions.keyword.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.keyword.showConfirm(isShow));
	},
});

const KeywordPage = (props: IKeywordPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allKeywords,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;
	const [catID, setCatID] = useState<number>(0);


	const { product_category_all } = useLookupProductCategory();
	var handleReloadData = useCallback(() => {
		handleReloadAllData(catID);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catID]);

	useEffect(() => {
		handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [catID]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên`} minWidth={150}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='slug' minWidth={150} caption={`Slug`}></DxTableColumn>,
			// <DxTableColumn key={createUUID()} dataField='catID' minWidth={150} caption={`Danh mục sản phẩm`}>
			// 	<DxLookup dataSource={product_category_all} valueExpr={'id'} displayExpr={'name'} />
			// </DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='stt' minWidth={150} caption={`Số thứ tự`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product_category_all]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						<li className='col col-lg-3'>
							<ComboBoxProductCategory
								name={'collectID'}
								onValueChanged={(value) => {
									setCatID(value ? parseInt(value) : 0);
								}}
								value={catID}
								placeholder='Nhập danh mục sản phẩm...'
								sizeClass='xs'
							/>
						</li>
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allKeywords}
								keyExpr='id'
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<KeywordDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordPage);
