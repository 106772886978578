import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
import { CollectionItemModel } from "../../models/collections/CollectionItemModel";
import { CollectionItemRequest } from "../../models/collections/CollectionItemRequest";
import { CollectionItemResponse } from "../../models/collections/CollectionItemResponse";

export const CollectionItemApi = new class CollectionItemApi extends ApiBase<CollectionItemModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetColectionItemByCollectionAsync(data: CollectionItemRequest) {
		return ApiClient.GET(`${this._baseUrl}/bo-suu-tap?collectID=${data.collectID}`);
	}
}({ baseUrl: '/collections-item' });